/*!
 * Global Styles Entry Point
 *
 * Main entry point for the styles used in our app.
 * Framework and components specific to this project
 * should be imported here. To change framework styles,
 * do so in the referenced common.ui project.
 *
 * Note: Styles defined here are not namespaced.
 * Therefore, they are shared across the entire app.
 * Specific component styles are set in the angular
 * components themselves.
 */
// Framework
@import 'node_modules/kup.common.ui/src/styles/framework';

// Components
@import './components/components.spinner';
@import './components/components.ng-select';
@import './components/componets.mat-stepper';

// Objects
@import 'objects/objects.list-bare';
@import 'objects/objects.list-inline';

// Utilities
@import './variables';

// Global styles
@import 'global/global.styles';
