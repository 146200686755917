$color-app-primary: #5b7e82;
$color-app-primary-font: white;
$color-focused-label: #81c341;
$color-info: cornflowerblue;
$color-warn: orange;
$color-error: red;
$color-validation-error: #660033;
$color-subtle-text: rgba(0, 0, 0, 0.38);
$color-subtle-control-border: rgba(0, 0, 0, 0.1);
$color-form-divider: rgba(0, 0, 0, 0.1);
