.o-list-inline {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0;
}

.o-list-inline__item {
  display: inline-flex;
  align-items: center;
}

.o-list-inline__item + .o-list-inline__item {
  margin-left: 8px;
}
