@import '~@ng-select/ng-select/themes/material.theme.css';
@import '../_variables';

// overrides
.ng-select {
  padding-bottom: 1.5em;
}

.remove-bottom-padding {
  padding-bottom: 0;
}

.ng-select .ng-select-container {
  min-height: 40px;
}

// required styling
.ng-select.ng-select-required .ng-placeholder:after {
  content: ' *';
}

.ng-select.ng-select-required.ng-select-opened .ng-placeholder:after {
  color: $color-validation-error;
}

// invalid styling
.ng-select.ng-invalid.ng-touched .ng-select-container:after {
  border-color: $color-validation-error;
}

.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  color: $color-validation-error;
}

.ng-select.ng-invalid.ng-touched .ng-placeholder {
  color: $color-validation-error;
}

.ng-select.ng-select-focused.ng-invalid.ng-touched .ng-placeholder {
  color: $color-validation-error;
}

// Make the color of ng-select chips in multi-valued controls consistent with the theme
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  .ng-value {
    margin-top: 4px;
    background-color: $color-app-primary;
    .ng-value-label {
      padding: 0px 8px 0px 0px;
    }
  }
}