@import "../_variables";

.mat-horizontal-stepper-header {
    pointer-events: none !important;
}

.mat-step-header .mat-step-icon-state,
.mat-step-header .mat-step-icon {
    height: 48px;
    width: 48px;
    margin-top: -10px;
}

.mat-step-header .mat-step-icon-state,
.mat-step-header .mat-step-icon .mat-icon {
    font-size: 36px;
    height: 36px;
    width: 36px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: $color-app-primary !important;
}
